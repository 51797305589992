// partners
import part1 from "../assets/part-01.png";
import part2 from "../assets/part-02.png";
import part3 from "../assets/part-03.png";
import part4 from "../assets/part-04.png";
import part5 from "../assets/part-05.png";
import part6 from "../assets/part-06.png";
// portfolio
import port1 from "../assets/port-01.png";
import port2 from "../assets/port-02.png";
import port3 from "../assets/port-03.png";
// featuers
import feat1 from "../assets/feat-01.svg";
import feat2 from "../assets/feat-02.svg";
import feat3 from "../assets/feat-03.svg";
import feat4 from "../assets/feat-04.svg";
// slider
import slide1 from "../assets/slider-01 (1).jpg";
import slide2 from "../assets/slider-01 (2).jpg";
import slide3 from "../assets/slider-03.jpg";
import slide4 from "../assets/slider-04.jpg";
// portfolio
import blog1 from "../assets/blog-01.png";
import blog2 from "../assets/blog-02.png";
import blog3 from "../assets/blog-03.png";
import blog4 from "../assets/blog-04.png";
import blog5 from "../assets/blog-05.png";
// rev
import av from "../assets/av.svg";
export const navLinks = [
  {
    enTitle: "home",
    arTitle: "الرئيسية",
    path: "/",
  },
  {
    enTitle: "about us",
    arTitle: "من نحن",
    path: "/about",
  },
  {
    enTitle: "real state",
    arTitle: "العقارات",
    path: "/real",
  },
  {
    enTitle: "Services",
    arTitle: "الخدمات",
    path: "/serv",
  },
  {
    enTitle: "Blog",
    arTitle: "المدونة",
    path: "/blog",
  },
  {
    enTitle: "contact us",
    arTitle: "تواصل معنا",
    path: "/contact/form",
  },
];
export const partners = [part6, part5, part4, part3, part2, part1];
export const portfolio = [
  {
    id: 1,
    img: port1,
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
  },
  {
    id: 2,
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
    img: port2,
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
  },
  {
    id: 3,
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
    img: port3,
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
  },
  {
    id: 4,
    img: port1,
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
  },
  {
    id: 5,
    img: port2,
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
  },
  {
    id: 6,
    img: port3,
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
  },
  {
    id: 7,
    img: port1,
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
  },
  {
    id: 8,
    img: port2,
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
  },
  {
    id: 9,
    img: port3,
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
  },
  {
    id: 10,
    img: port1,
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
  },
  {
    id: 11,
    img: port2,
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
  },
  {
    id: 12,
    img: port3,
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
  },
  {
    id: 13,
    img: port1,
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
  },
  {
    id: 14,
    img: port2,
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
  },
  {
    id: 15,
    img: port3,
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
  },
  {
    id: 16,
    img: port1,
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
  },
  {
    id: 17,
    img: port2,
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
  },
  {
    id: 18,
    img: port3,
    enTitle: "Long established fact",
    arTitle: "حقيقة راسخة منذ زمن طويل",
    arLocation: "مصر",
    enLocation: "Egypt",
    slider: [slide1, slide2, slide3, slide4],
    desc: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
  },
];
export const stats = [
  {
    number: "2k+",
    enTitle: "projects",
    arTitle: "مشروع",
  },
  {
    number: "450",
    enTitle: "clients",
    arTitle: "عميل",
  },
  {
    number: "170",
    enTitle: "deals",
    arTitle: "اتفاق",
  },
];
export const services = [
  {
    number: 1,
    enTitle: "Real Estate Finance",
    arTitle: "التمويل العقاري",
    enDesc: "Praesent vel interdum nulla, et volutpa posuere vestibulum.",
    arDesc: "اشطا صباح الفل علي احلي ناس في البلد",
  },
  {
    number: 2,
    enTitle: "Real Estate Finance",
    arTitle: "التمويل العقاري",
    enDesc: "Praesent vel interdum nulla, et volutpa posuere vestibulum.",
    arDesc: "اشطا صباح الفل علي احلي ناس في البلد",
  },
  {
    number: 3,
    enTitle: "Real Estate Finance",
    arTitle: "التمويل العقاري",
    enDesc: "Praesent vel interdum nulla, et volutpa posuere vestibulum.",
    arDesc: "اشطا صباح الفل علي احلي ناس في البلد",
  },
];
export const feat = [
  {
    img: feat1,
    title: "Volutpat estpraesent",
  },
  {
    img: feat2,
    title: "Volutpat estpraesent",
  },
  {
    img: feat3,
    title: "Volutpat estpraesent",
  },
  {
    img: feat4,
    title: "Volutpat estpraesent",
  },
];
export const filters = [
  {
    enTitle: "building",
    arTitle: "المباني",
    filters: [
      {
        enTitle: "Apartments",
        arTitle: "الشقق",
      },
      {
        enTitle: "villa",
        arTitle: "فيلا",
      },
      {
        enTitle: "roof",
        arTitle: "سطح",
      },
    ],
  },
  {
    enTitle: "location",
    arTitle: "الموقع",
    filters: [
      {
        enTitle: "Makkah",
        arTitle: "مكة",
      },
      {
        enTitle: "el-madinah",
        arTitle: "المدينة",
      },
      {
        enTitle: "El-ryad",
        arTitle: "الرياض",
      },
    ],
  },
];
export const blogs = [
  {
    id: 1,
    img: blog1,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz masr",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 2,
    img: blog2,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megzooo",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 3,
    img: blog3,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 4,
    img: blog4,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 5,
    img: blog5,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 6,
    img: blog3,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz masr",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 7,
    img: blog4,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 8,
    img: blog5,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 9,
    img: blog5,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 10,
    img: blog5,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 11,
    img: blog5,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 12,
    img: blog5,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 13,
    img: blog5,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 14,
    img: blog5,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 15,
    img: blog5,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 16,
    img: blog5,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 17,
    img: blog5,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 18,
    img: blog5,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 19,
    img: blog5,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 20,
    img: blog5,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
  {
    id: 21,
    img: blog5,
    title: "Praesent vel interdum ",
    desc: "Praesent vel interdum nulla, et volutpat est. Praesent consectetur aliquet ligula.",
    date: "NOVEMBER 26, 2023  |  BY Megz",
    allDesc:
      "There are many variationspassages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passageof Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessarymaking this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore Always free from repetition, injectehumour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't Anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeatpredefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.Repetition, injected humour, or non-characteristic words etc.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
];
export const rev = [
  {
    img: av,
    name: "maged elshafey",
    rev: "Praesent vel interdum nulla, et volutpat est. Praesent c",
  },
  {
    img: av,
    name: "Esraa Mostafa",
    rev: "Praesent vel interdum nulla, et volutpat est. Praesent c",
  },
  {
    img: av,
    name: "Manar El hefnawy",
    rev: "Praesent vel interdum nulla, et volutpat est. Praesent c",
  },
  {
    img: av,
    name: "Mahmoud Hamza",
    rev: "Praesent vel interdum nulla, et volutpat est. Praesent c",
  },
];
